var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", "max-width": "1200px" },
      on: { "click:outside": _vm.close }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            [
              _c(
                "v-toolbar-title",
                [
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          '"' + _vm.selectedContract.file_name + '" Promos'
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "rounded-0",
            attrs: {
              headers: _vm.headers,
              items: _vm.promos,
              "loading-text": "Searching Available Data...Please Wait",
              loading: _vm.loading,
              color: "success",
              "fixed-header": "",
              "hide-default-footer": "",
              "items-per-page": _vm.pageSize,
              "item-class": _vm.itemRowBackground,
              "item-key": "id",
              "loader-height": "5",
              "no-data-text": "No Data to Display",
              height: "600px",
              dense: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.start_dt",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.start_dt
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$config.formatDate(item.start_dt))
                            )
                          ])
                        : _c("span", [_vm._v("-")])
                    ]
                  }
                },
                {
                  key: "item.end_dt",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.end_dt
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$config.formatDate(item.end_dt)))
                          ])
                        : _c("span", [_vm._v("-")])
                    ]
                  }
                },
                {
                  key: "item.updated_on",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.updated_on
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$config.formatDate(item.updated_on))
                            )
                          ])
                        : _c("span", [_vm._v("-")])
                    ]
                  }
                },
                {
                  key: "item.link",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            icon: "",
                                            fab: "",
                                            dark: "",
                                            "x-small": "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.goToPromo(item)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-link-variant ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Navigate to this promotion")])]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }