<template>
  <v-dialog :value="value" persistent @click:outside="close" max-width="1200px">
    <v-card flat>
      <v-toolbar >
        <v-toolbar-title>
          <v-card-title>
            {{ `"${selectedContract.file_name}" Promos` }}
          </v-card-title>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table 
        :headers="headers" 
        :items="promos"
        loading-text="Searching Available Data...Please Wait"
        :loading="loading"
        class="rounded-0"
        color="success"
        fixed-header
        hide-default-footer
        :items-per-page="pageSize"
        :item-class="itemRowBackground"
        item-key="id"
        loader-height="5"
        no-data-text="No Data to Display"
        height="600px"
        dense>
        <template #[`item.start_dt`]="{ item }">
          <span v-if="item.start_dt">{{$config.formatDate(item.start_dt)}}</span>
          <span v-else>-</span>
        </template>
        <template #[`item.end_dt`]="{ item }">
          <span v-if="item.end_dt">{{$config.formatDate(item.end_dt)}}</span>
          <span v-else>-</span>
        </template>
        <template #[`item.updated_on`]="{ item }">
          <span v-if="item.updated_on">{{$config.formatDate(item.updated_on)}}</span>
          <span v-else>-</span>
        </template>
        <template #[`item.link`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="mr-4"
                icon
                fab
                dark
                x-small
                color="primary"
                @click="goToPromo(item)">
                <v-icon>
                  mdi-link-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Navigate to this promotion</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
  import Promos from '@/axios/promotion-endpoint.js'
  import { displayAlert } from '@/mixins/alert'
  import { utils } from '@/mixins/utils'
  export default {
    name: 'ContractPromosModal',
    mixins: [displayAlert, utils],
    props: {
      value: Boolean,
      selectedContract: Object
    },
    data: () => ({
      loading: true,
      promos: [],
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100]
    }),
    computed: {
      disablePrevious () {
        return ((this.page - 1) * this.pageSize) === 0
      },
      disableNext () {
        return this.promos.length < this.pageSize
      },
      headers () {
          return [
            { text: 'Promotion Number', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promo_number' },
            { text: 'Promotion Name', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promo_name' },
            { text: 'Promotion Category', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promo_category_name' },
            { text: 'Promotion Type', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promotion_type' },
            { text: 'Start Date', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'start_dt' },
            { text: 'End Date', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'end_dt' },
            { text: 'Last Updated', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'updated_on' },
            { text: '', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'link' }
          ]
      }
    },
    async created () {
      await this.getPromos()
    },
    methods: {
      close () {
        this.$emit('closePromos')
      },
      async getPromos () {
        this.loading = true
        try {
          const { data } = await Promos.getPromosByIds(this.selectedContract.promo_ids)
          if (data?.length > 0) {
            this.promos = data
          }
        } catch (err) {
          this.handleError(`Failed to get promotions due to ${err}`)
        } finally {
          this.loading = false
        }
      },
      previous () {
        this.page = this.page - 1
        if (this.page < 1) {
          this.page = 1
        }
        this.getPromoList()
      },
      next () {
        this.page = this.page + 1
        this.getPromoList()
      },
      itemRowBackground (item) {
        return this.promos.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
      },
      goToPromo (promo) {
        window.open(`/promo?id=${promo.promo_id}`)
      }
    }
  }
</script>